
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import { Pageniation as Table } from "@/utils/decorator";
import t from "@common/src/i18n/t";
const base = namespace("base");
const learning = namespace("learning");
@Component({
  components: {}
})
@Table("loadListData")
export default class InteractionMine extends Vue {
  @base.Action getDictionaryList;
  // 学习
  @learning.Action myLearnInvitationPage;
  @learning.Action myInvitationCommentPage;
  @learning.Action thumbsUpLearnInvitation;
  @learning.Action deleteInvitationComment;
  @learning.Action deleteLearnInvitation;

  emptyToLine = emptyToLine;
  dayjs = dayjs;
  navigateView: any = "0";
  contentList: any = [];
  commentList: any = [];
  isShowDelete: any = false;
  isShowCommentDelete: any = false;
  currentItemCode: any = "";

  get breadData() {
    return [
      { name: t("v210801.interactive-qa"), path: "/learning/interaction-list" },
      {
        name: t("v210801.my-interaction")
      }
    ];
  }
  created() {
    this.init();
  }
  get id() {
    return this.$route.params.id;
  }
  init() {
    this.loadListData();
  }
  getSrcList(urls, index) {
    let newList = urls.map(v => v.annexUrl);
    return newList.slice(index).concat(newList.slice(0, index));
  }
  scrollToView(id) {
    window.location.href = id;
  }
  ChangeNavigate(type) {
    this.navigateView = type;
    this.mix_pageNum = 1;
    this.mix_pageSize = 10;
    this.loadListData();
  }
  @Table.LoadDataMethod()
  loadListData() {
    if (this.navigateView === "0") {
      return this.loadInvitListData();
    } else {
      return this.loadCommentListData();
    }
  }
  // 我的帖子列表
  loadInvitListData() {
    return this.myLearnInvitationPage({
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.contentList = data.data.list;
      return data;
    });
  }
  // 我的评论列表
  loadCommentListData() {
    return this.myInvitationCommentPage({
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.commentList = data.data.list;
      return data;
    });
  }
  // 删除帖子按钮
  clickDelete(item) {
    this.isShowDelete = true;
    this.currentItemCode = item.invitationCode;
  }
  // 删除帖子
  handleDelete() {
    this.isShowDelete = false;
    this.deleteLearnInvitation({
      invitationCode: this.currentItemCode
    }).then(data => {
      this.$message.success(t("v210831.the-post-was"));
      this.loadListData();
    });
  }
  // 跳转帖子详情
  toInteractionView(item) {
    this.$router.push("/learning/interaction-view/" + item.invitationCode);
  }
  // 跳转帖子详情 我的评论位置
  toInteractionCommentView(item) {
    this.$router.push("/learning/interaction-view/" + item.invitationCode + "?comment=1");
  }
  // 点赞帖子
  handlePraise(item) {
    if (item.thumbsUpBySelf === "true") {
      this.$message.warning(t("v210831.you-like-it"));
      return;
    }
    this.thumbsUpLearnInvitation({
      invitationCode: item.invitationCode
    }).then(data => {
      this.$message.success(t("v210831.praise-succeeded"));
      this.loadListData();
    });
  }
  // 删除评论按钮
  clickDeleteComment(item) {
    this.isShowCommentDelete = true;
    this.currentItemCode = item.commentCode;
  }
  // 删除评论
  handleDeleteComment() {
    this.isShowCommentDelete = false;
    this.deleteInvitationComment({
      commentCode: this.currentItemCode
    }).then(data => {
      this.$message.success(t("v210831.comments-deleted-successfully"));
      this.loadListData();
    });
  }
}
